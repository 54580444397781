import React,{useEffect} from 'react';
import Spin from "../img/Spin.gif";

function Spinner() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
     <div className='text-center' style={{marginTop:"10rem"}}>
      <img src={Spin} alt='spinner'></img>
    </div> 
  )
}

export default Spinner