import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserProfile=()=> {

  const token=localStorage.getItem("TOKEN");
  const BASEURL=process.env.REACT_APP_BASE_URL;

  // States
  const[fname,setFname] = useState("");
  const[lname,setLname] = useState("");
  const[email,setEmail] = useState("");
  const[address,setAddress] = useState("");
  const[pno,setPno] = useState("");
  const[postal,setPostal] = useState("");
  const[apno,setApno] = useState("");
  const [user,setUser] = useState();

  const navigate=useNavigate();
  

  // This will dispaly update button when user types anything
  const getButtonDisplay = ()=>{
    const fname = document.getElementById('fname').value;
    const lname = document.getElementById('lname').value;
    const email = document.getElementById('email').value;
    const address = document.getElementById('address').value;
    const pno = document.getElementById('pno').value;
    const postal = document.getElementById('postal').value;
    const apno = document.getElementById('apno').value;

    if (fname || lname || email || address || pno || postal || apno) {
      document.getElementById('disp').style.display = 'block';
    }else{
      document.getElementById('disp').style.display = 'none';
    }
  }

  //This will send updated details to backend
  const handleUpdate = async(e)=>{
    
     e.preventDefault();

     try {
      const res = await fetch(`${BASEURL}/updateUserProfile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            },
            body: JSON.stringify({ fname,lname,email,address,pno,postal,apno })
        })
      
        await res.json();

        if (res.status===201) {
          document.getElementById('disp').style.display = 'none';
          alert('heeeee...success');
          //following is not final, have to think about it
          window.location.reload();
        }
        else{
          alert('not success');
        }
     } catch (error) {
      console.log(error);
     }
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const getProfile = async()=>{        
        try {
            const res=await fetch(`${BASEURL}/userProfile`,{
                method:"GET",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":token
                }
            });
    
            const data=await res.json();

            if(res.status===201){
                setUser(data);
            }
            else{
                alert("Please login");
                handleLogout();
            }
        } catch (error) {
            console.log("error in profile fetching trycatch!");
        }
        
    }

    useEffect(()=>{
        getProfile();
        // eslint-disable-next-line
    },[]);


  return (
    <div>
      <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Your Profile</h1>
                </div>
      </div>
        <form style={{alignItems:"center"}}>
        <div className="row mb-2">
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setFname(e.target.value); getButtonDisplay()}} className="form-control" type="text" id="fname" name="fname" placeholder={user?.fname||''} />
              <label className="form-label" htmlFor="fname">First name</label>
            </div>
          </div>
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setLname(e.target.value); getButtonDisplay()}} type="text" id="lname" name="lname" className="form-control" placeholder={user?.lname||''} />
              <label className="form-label" htmlFor="lname">Last name</label>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setEmail(e.target.value); getButtonDisplay()}} type="email" id="email" name="email" className="form-control" placeholder={user?.email||''} />
              <label className="form-label" htmlFor="email">Email</label>
            </div>
          </div>
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setAddress(e.target.value); getButtonDisplay()}} type="text" id="address" name="address" className="form-control" placeholder={user?.address||''} />
              <label className="form-label" htmlFor="address">Address</label>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setPno(e.target.value); getButtonDisplay()}} type="tel" id="pno" name="pno" className="form-control" placeholder={user?.pno||''} />
              <label className="form-label" htmlFor="pno">Contact No.</label>
            </div>
          </div>
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setPostal(e.target.value); getButtonDisplay()}} type="text" id="postal" name="postal" className="form-control" placeholder={user?.postal||''} />
              <label className="form-label" htmlFor="postal">Postal code</label>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <div data-mdb-input-init className="form-outline">
              <input onChange={(e)=>{setApno(e.target.value); getButtonDisplay()}} type="tel" id="apno" name="apno" className="form-control" placeholder={user?.apno||''} />
              <label className="form-label" htmlFor="apno">Alternate Contact No.</label>
            </div>
          </div>
        </div>
        <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
        <button onClick={handleUpdate} id='disp' style={{width:"500px",alignItems:"center"}}>Update profile</button>
        </div>
      </form>
    </div>
    </div>
  )
}

export default UserProfile



// import React, { useEffect, useState } from 'react';

// const UserProfile=()=> {
//     const token=localStorage.getItem("TOKEN");

//     const [user,setUser] = useState();
//     const BASEURL=process.env.REACT_APP_BASE_URL;

//     const getProfile = async()=>{        
//         try {
//             const res=await fetch(`${BASEURL}/userProfile`,{
//                 method:"GET",
//                 headers:{
//                     "Content-Type":"application/json",
//                     "Authorization":token
//                 }
//             });
    
//             const data=await res.json();

//             if(res.status===201){
//                 setUser(data);
//             }
//             else{
//                 alert("Cannot fetch profile!");
//             }
//         } catch (error) {
//             console.log("error in profile fetching trycatch!");
//         }
        
//     }

//     useEffect(()=>{
//         getProfile();
//         // eslint-disable-next-line
//     },[]);

//   return (
//     <div style={{marginTop:"40px"}}>
//       <h1>Profile</h1>
//   <form style={{alignItems:"center"}}>
//   <div className="row mb-2">
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input className="form-control" type="text" id="fname" name="fname" placeholder={user?.fname||''} />
//         <label className="form-label" htmlFor="fname">First name</label>
//       </div>
//     </div>
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="text" id="lname" name="lname" className="form-control" placeholder={user?.lname||''} />
//         <label className="form-label" htmlFor="lname">Last name</label>
//       </div>
//     </div>
//   </div>

//   <div className="row mb-2">
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="email" id="email" name="email" className="form-control" placeholder={user?.email||''} />
//         <label className="form-label" htmlFor="email">Email</label>
//       </div>
//     </div>
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="text" id="address" name="address" className="form-control" placeholder={user?.address||''} />
//         <label className="form-label" htmlFor="address">Address</label>
//       </div>
//     </div>
//   </div>

//   <div className="row mb-2">
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="tel" id="pno" name="pno" className="form-control" placeholder={user?.pno||''} />
//         <label className="form-label" htmlFor="pno">Contact No.</label>
//       </div>
//     </div>
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="text" id="postal" name="postal" className="form-control" placeholder={user?.postal||''} />
//         <label className="form-label" htmlFor="postal">Postal code</label>
//       </div>
//     </div>
//   </div>

//   <div className="row mb-2">
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="date" id="startDate" name="startDate" className="form-control" placeholder={user?.startDate||''} />
//         <label className="form-label" htmlFor="startDate">Start date</label>
//       </div>
//     </div>
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="tel" id="apno" name="apno" className="form-control" placeholder={user?.apno||''} />
//         <label className="form-label" htmlFor="apno">Alternate Contact No.</label>
//       </div>
//     </div>
//   <div className="row mb-2">
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="password" id="pwd" name="pwd" className="form-control" placeholder={user?.pwd||''} />
//         <label className="form-label" htmlFor="pwd">Password</label>
//       </div>
//     </div>
//     <div className="col">
//       <div data-mdb-input-init className="form-outline">
//         <input type="password" id="cpwd" name="cpwd" className="form-control" placeholder={user?.cpwd||''} />
//         <label className="form-label" htmlFor="cpwd">Confirm Password</label>
//       </div>
//     </div>
//   </div>
//   <div className="col">
//     Delivery/Self-checkout                        :   
//     <div className="form-check form-check-inline">
//   <input className="form-check-input" type="radio" name="choice" id="delivery" value="d" checked={user?.choice === 'd'||''} readOnly/>
//   <label className="form-check-label" htmlFor="delivery">Delivery</label>
//     </div>
//     <div className="form-check form-check-inline">
//   <input className="form-check-input" type="radio" name="choice" id="pickup" value="p" checked={user?.choice === 'p'|| ''} readOnly/>
//   <label className="form-check-label" htmlFor="pickup">Pick up</label>
//     </div>
//   </div>

//   </div>
// </form>
//   </div>
//   )
// }

// export default UserProfile