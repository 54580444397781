import React, {useEffect,useRef} from 'react'
import { Link } from 'react-router-dom';
import checkNetworkStatus from './CheckNetwork';
import { HashLink } from 'react-router-hash-link';

const Footer=(props)=> {
  const navbarCollapseRef = useRef(null);

  const scrollToPosition = (el) => {
    const yOffset = -90; // Adjust this value according to your navbar height or desired offset
    const yPosition = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: yPosition, behavior: 'smooth' });
};

    useEffect(() => {
        const checkInternetConnection = () => {
          checkNetworkStatus((speed) => {
            if (speed < 10000000) { // Adjust the speed threshold as needed
              props.setShowSpinner(true);
            } else {
              props.setShowSpinner(false);
            }
          });
        };
    
        checkInternetConnection(); // Initial check
        const interval = setInterval(checkInternetConnection, 10000); // Check every 10 seconds
    
        const handleOnline = () => {
          checkInternetConnection();
        };
    
        const handleOffline = () => {
          props.setShowSpinner(true);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          clearInterval(interval);
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
      }, []);


  return (
    <>
    {
    !props.showSpinner && 
    <div>
        <div className="container-fluid bg-dark text-light footer pt-1 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container d-flex justify-content-center">
                <div className="row justify-content-center" style={{width:"100%",justifyContent:"space-evenly"}}>
                <div className="col-lg-3 col-md-3" style={{textAlign:"center"}}>
                        <h4 className="section-title ff-secondary text-start text-end text-primary fw-normal mb-4" style={{marginTop:"2rem"}}>Follow us on</h4>
                        <div className="social-icons d-flex" style={{justifyContent:"center"}}>
                            <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/mumbaiyacafe.ca?igsh=MWY2ODN2eHlvYno3Mg%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/share/fbGmHctdKapvPT8J/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                        </div>
                      
                    </div>
                    <div className="col-lg-3 col-md-3" style={{textAlign:"center"}}>
                        <h4 className="section-title ff-secondary text-start text-end text-primary fw-normal mb-4" style={{marginTop:"2rem"}}>Company</h4><br/>
                        <HashLink to="/##aboutUs" scroll={scrollToPosition}>About Us</HashLink><br/>
                        <HashLink to="/##contactUs" scroll={scrollToPosition}>Contact Us</HashLink><br/>
                        <HashLink to="/##menu" scroll={scrollToPosition}>Menu</HashLink><br/>
                        <a className="footer-Link" href="/##home">To the top</a><br/>
                    </div>
                    <div className="col-lg-3 col-md-3 px-2" style={{textAlign:"center"}}>
                        <h4 className="section-title ff-secondary text-start text-end text-primary fw-normal mb-4" style={{marginTop:"2rem"}}>Contact</h4>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i><a href='https://maps.app.goo.gl/uJDcLe5rtowB9cGB7' target='_blank'>West Parking Lot, 2223 Victoria Ave E, Regina, SK S4N 6E4</a></p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><a href="tel:+16395541060">+1 639 554 1060</a></p>
                        <p className="mb-2"><i className="fa fa-envelope me-3"></i><a href="mailto:mumbaiyacafe@gmail.com">mumbaiyacafe@gmail.com</a></p>
    
                    </div>
                    <div className="col-lg-3 col-md-3 px-2" style={{textAlign:"center"}}>
                        <h4 className="section-title ff-secondary text-start text-end text-primary fw-normal mb-4" style={{marginTop:"2rem"}}>Open Hours</h4>
                        <p className="text-light fw-normal">Wednesday to Sunday<br/>
                        <span>03:00 PM - 09:00 PM</span></p>
                        <p className="text-light fw-normal">Closed on<br/>
                        <span>Monday and Tuesday</span></p>
                        
                    </div>
                    
                </div>
            </div>
            <div className="container"> 
                <div className="copyright" style={{marginBottom:"-8rem !important"}}>
                    <div style={{margin:"0px !important"}}>
                        <div className="col-md-6 text-center text-md-start mb-md-0 pb-3"> 
                          {/* added pb-3 above */}
                            &copy; <Link  className="border-bottom" href="#" style={{marginBottom:"2rem"}}>Mumbaiya Cafe</Link>, All Rights Reserved. 
							
							          {/* Designed By <Link  className="border-bottom" href="https://htmlcodex.com">HTML Codex</Link><br/><br/> */}
                        {/* <span style={{position: "relative", top: "-1rem" }}>Distributed By<Link className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</Link></span> */}
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    </>
  )
}

export default Footer






