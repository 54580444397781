import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

//https://codesandbox.io/p/sandbox/remember-me-localstorage-zz9il?file=%2Fsrc%2Frem.js%3A99%2C1-99%2C4
//REMEMBER ME FUNCTIONALITY. With exprire: 
//https://github.com/nextauthjs/next-auth/discussions/3794

const Login=(props)=> {

  const [user,setUser] = useState({
    email:"",
    pwd:""
  })

  const navigateTo=useNavigate();
  const BASEURL=process.env.REACT_APP_BASE_URL;

  let name,value;
  const handleInputs = (e)=>{
    name = e.target.name;
    value=e.target.value;

    setUser({...user,[name]:value});
  }

  const postData=async (e)=>{
    e.preventDefault();
    const {email,pwd}=user;

    const res= await fetch(`${BASEURL}/login`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body:JSON.stringify({
        email,pwd
      })
    });

    const data = await res.json();

    if(res.status===201){
      localStorage.setItem("TOKEN",data.token);
      props.setAuth(data.token);
      if(data.role==='u'){
          props.setUserRole(data.role);
          localStorage.setItem("userRole",data.role);
          console.log("role :",props.userRole);
          navigateTo('/tiffin');        
      }else if(data.role==='a'){
        props.setUserRole(data.role);
        localStorage.setItem("userRole",data.role);
        navigateTo('/admin');
      }
    }
    else if(data.message==="Please fill all the fields"){
      alert("Please fill all the details")
    }
    
    else{
      alert("User can not login!");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Login</h1>
                </div>
      </div>
       <form style={{alignItems:"center",marginTop:"100px"}}>
  <div className="row mb-2">
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="text" id="email" className="form-control" name='email' value={user.email} onChange={handleInputs}/>
        <label className="form-label" htmlFor="email">Email</label>
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="password" id="pwd" className="form-control" name='pwd' value={user.pwd} onChange={handleInputs}/>
        <label className="form-label" htmlFor="pwd">Password</label>
      </div>
    </div>
  </div>
  <button data-mdb-ripple-init type="button" className="btn btn-primary btn-block mb-2" onClick={postData}>Login</button>
    </form>
    </div>
    </div>
  )
}

export default Login
