import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Menu() {
    const [menu, setMenu] = useState({
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
    });
    const [weekStartDate, setWeekStartDate] = useState(null);
    const [weekEndDate, setWeekEndDate] = useState(null);

    const [tuesday, setTuesday] = useState(null);
    const [wednesday, setWednesday] = useState(null);
    const [thursday, setThursday] = useState(null);
    const [friday, setFriday] = useState(null);

    const BASEURL=process.env.REACT_APP_BASE_URL;
    const navigateTo=useNavigate();
    const token = localStorage.getItem("TOKEN");


    const handleChange = (e) => {
        const { name, value } = e.target;
        setMenu({
            ...menu,
            [name]: value,
        });
    };

    const handleLogout = () => {
        localStorage.clear();
        navigateTo("/");
        window.location.reload();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    const handleSubmit = async(e) => {
        e.preventDefault();
  
            try {
                const res = await fetch(`${BASEURL}/addMenu`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:token
                },
                body: JSON.stringify({ weekStartDate, tuesday, wednesday, thursday, friday, weekEndDate, menu })      
          })
            await res.json();
    
            if (res.status===201) {
             const inputs = document.querySelectorAll('input');

            inputs.forEach(input => {
                input.readOnly = true;
            });
                alert("done");
            } else if(res.status===401){
                alert("Please login !");
                handleLogout();
            }else{
                alert("not done");
            }
            } catch (error) {
                console.log(error);
            }
       
     };

     useEffect(()=>{
        
        const today = new Date();

        const startOfWeek = new Date(today);
        const tues = new Date(today);
        const wed = new Date(today);
        const thu = new Date(today);
        const fri = new Date(today);
        const endOfWeek = new Date(today);

        startOfWeek.setDate(today.getDate() - today.getDay() + 1);
        tues.setDate(today.getDate() - today.getDay() + 2);
        wed.setDate(today.getDate() - today.getDay() + 3);
        thu.setDate(today.getDate() - today.getDay() + 4);
        fri.setDate(today.getDate() - today.getDay() + 5);
        endOfWeek.setDate(today.getDate() - today.getDay() + 6); // End of the current week (Saturday)

        setWeekStartDate(startOfWeek.toDateString()); //converted in string
        setWeekEndDate(endOfWeek.toDateString());
        setTuesday(tues.toDateString()); //converted in string
        setWednesday(wed.toDateString());
        setThursday(thu.toDateString());
        setFriday(fri.toDateString());

     },[])

    return (
        <div className="container-xxl position-relative p-0">
             
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Add Menu for the week from {weekStartDate} to {weekEndDate}</h1>
                </div>
      </div>
      <div className="container">

            <div className="menu-form">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div>
                        <label className="col" htmlFor="monday">Monday : </label>
                        <label className="col">: {weekStartDate} ::</label>
                         : <input className="col" style={{width:"500px"}}
                            type="text"
                            id="monday"
                            name="monday"
                            placeholder="Enter food item for Monday"
                            value={menu.monday}
                            onChange={handleChange}
                        />  
                        </div>
                    </div>
                    
                    <div className="row">
                    <div>
                        <label className="col" htmlFor="tuesday">Tuesday:</label>
                        <label className="col">: {tuesday} ::</label>
                        <input className="col" style={{width:"500px"}}
                            type="text"
                            id="tuesday"
                            name="tuesday"
                            placeholder="Enter food item for Tuesday"
                            value={menu.tuesday}
                            onChange={handleChange}
                        />
                    </div>
                    </div>
                    <div className="row">
                    <div>
                        <label className="col" htmlFor="wednesday">Wednesday:</label>
                        <label className="col">: {wednesday} ::</label>
                        <input className="col" style={{width:"500px"}}
                        
                            type="text"
                            id="wednesday"
                            name="wednesday"
                            placeholder="Enter food item for Wednesday"
                            value={menu.wednesday}
                            onChange={handleChange}
                        />
                    </div>
                    </div>
                    <div className="row">
                        <div>
                        <label className="col" htmlFor="thursday">Thursday:</label>
                        <label className="col">: {thursday} ::</label>
                        <input className="col" style={{width:"500px"}}
                            type="text"
                            id="thursday"
                            name="thursday"
                            placeholder="Enter food item for Thursday"
                            value={menu.thursday}
                            onChange={handleChange}
                        />
                        </div>
                    </div>
                    <div className="row">
                    <div>
                        <label className="col" htmlFor="friday">Friday:</label>
                        <label className="col">: {friday} ::</label>
                        <input className="col" style={{width:"500px"}}
                            type="text"
                            id="friday"
                            name="friday"
                            placeholder="Enter food item for Friday"
                            value={menu.friday}
                            onChange={handleChange}
                        />
                        </div>
                    </div>
                    <div className="row">
                        <div>
                        <label className="col" htmlFor="saturday">Saturday:</label>
                        <label className="col">: {weekEndDate} ::</label>
                        <input className="col" style={{width:"500px"}}
                            type="text"
                            id="saturday"
                            name="saturday"
                            placeholder="Enter food item for Saturday"
                            value={menu.saturday}
                            onChange={handleChange}
                        />
                        </div>
                        
                    </div>
                
                    <div className="row">
                    <div className='col-md-12'>
                        <button type="submit">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
}

export default Menu
