import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from 'chart.js/auto';

const TiffinGraph=()=> {

    const BASEURL=process.env.REACT_APP_BASE_URL;
    const [mealData, setMealData] = useState([]);
    const token = localStorage.getItem("TOKEN");
    const navigateTo=useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigateTo("/");
        window.location.reload();
    };

useEffect(()=>{
    const getAnalytics=async ()=>{
        const res=await fetch(`${BASEURL}/tiffinAnalytics`,{
            method:"GET",
            headers:{
                "Content-Type": "application/json",
                Authorization:token
            }
        })
    const data=await res.json();
    if(res.status===201){
        setMealData(data);
    }
    else if(res.status===401){
        alert("Please login")
        handleLogout();
    }
    else{
        alert("error");
    }
    }

    getAnalytics();
},[]);

    const getGraph=()=>{
        const ctx = document.getElementById('barGraph');

        if (mealData.length > 0) {
            const mealNames = mealData.map(item => item.canceled_meal);
            console.log("mealnames: ",mealNames);
            const cancelCounts = mealData.map(item => item.cancel_count);
    
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: mealNames,
                    datasets: [{
                        label: 'Cancel Count',
                        data: cancelCounts,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)'
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Meal Name'
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Cancel Count'
                            }
                        }
                    }
                }
            });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

  return (
    <div>
        <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Tiffin analytics</h1>
                </div>
      </div>
        <button onClick={()=>getGraph()}>Show graph</button>
      <canvas id="barGraph" width="200" height="50"></canvas>
    </div>
    </div>
  )
}

export default TiffinGraph
