import {React, useRef,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../img/mumbaiyaCafeLogo.png';

const Navbar=(props)=> {
  const userRole=localStorage.getItem("userRole");
  const auth=localStorage.getItem("TOKEN");

  const navbarCollapseRef = useRef(null);
  const scrollToPosition = (el) => {
    const yOffset = -70; // Adjust this value according to your navbar height or desired offset
    const yPosition = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: yPosition, behavior: 'smooth' });
};

  const handleNavItemClick = () => {
      if (navbarCollapseRef.current.classList.contains('show')) {
          navbarCollapseRef.current.classList.remove('show');
      }
  };

  //handling click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbarCollapse = document.getElementById('navbarCollapse');
      const navbarToggler = document.querySelector('.navbar-toggler');
      
      if (navbarCollapse.classList.contains('show') && !navbarCollapse.contains(event.target) && !navbarToggler.contains(event.target)) {
        navbarToggler.click();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
    {
    !props.showSpinner && 
    <div>
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark px-4 px-lg-5 py-4 py-lg-0" style={{ height: "87px" }}>
                <NavLink to="/" className="navbar-brand p-0">
                <HashLink to='/##home'><img className="me-10" src={Logo} style={{height:"180px",paddingTop:"0.1em"}} alt="Logo"/></HashLink>
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" style={{color:"yellow"}}>
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse navMini" id="navbarCollapse" ref={navbarCollapseRef}>
                    <div className="navbar-nav ms-auto py-0 pe-4">
                    { 
              !auth && userRole!==true &&
              <>
              <HashLink className="nav-link" to="/##home" onClick={handleNavItemClick}>Home</HashLink>
              <HashLink className="nav-link" to="/##aboutUs" scroll={scrollToPosition} onClick={handleNavItemClick}>About</HashLink>
              <HashLink className="nav-link" to="/##menu" scroll={scrollToPosition} onClick={handleNavItemClick}>Menu</HashLink>
              <HashLink className="nav-link" to="/##contactUs" scroll={scrollToPosition} onClick={handleNavItemClick}>Contact</HashLink>
              {/* <NavLink className="nav-link" to="/login" onClick={handleNavItemClick}>Login</NavLink>
              <NavLink className="nav-link" to="/register" onClick={handleNavItemClick}>Register</NavLink> */}
              </>
                }
                {/* {
              auth && userRole === 'a' &&
              <>
              <NavLink className="nav-link" to="/admin" onClick={handleNavItemClick}>Home</NavLink>
              <HashLink className="nav-link" to="/##aboutUs" onClick={handleNavItemClick}>About</HashLink>
              <HashLink className="nav-link" to="/##menu" onClick={handleNavItemClick}>Menu</HashLink>
              <HashLink className="nav-link" to="/##contactUs" onClick={handleNavItemClick}>Contact</HashLink>
              <NavLink className="nav-link" to="/registrations" onClick={handleNavItemClick}>Registrations</NavLink>
              <NavLink className="nav-link" to="/active" onClick={handleNavItemClick}>Active Users</NavLink>
              <NavLink className="nav-link" onClick={props.handleLogout}>Logout</NavLink>
              </>
                }
                {
              auth && userRole === 'u' &&
              <>
              <NavLink className="nav-link" to="/tiffin" onClick={handleNavItemClick}>Home</NavLink>
              <HashLink className="nav-link" to="/##aboutUs" onClick={handleNavItemClick}>About</HashLink>
              <HashLink className="nav-link" to="/##menu" onClick={handleNavItemClick}>Menu</HashLink>
              <HashLink className="nav-link" to="/##contactUs" onClick={handleNavItemClick}>Contact</HashLink>
              <NavLink className="nav-link" to="/userProfile" onClick={handleNavItemClick}>Profile</NavLink>
              <NavLink className="nav-link" onClick={props.handleLogout}>Logout</NavLink></>
               } */}
              
                    </div>
                </div>
        </nav>
    </div>
    }
    </>
  )
}

export default Navbar