import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const ActiveUsers=(props)=> {
    const BASEURL=process.env.REACT_APP_BASE_URL;
    const [active,setActive] = useState([]);
    const [selectedUser,setSelectedUser] = useState();
    const navigateTo=useNavigate();
   
    const showDetails=(index)=>{
        setSelectedUser(active[index]);
    }

    useEffect(()=>{
        const token = localStorage.getItem("TOKEN");
        const fetchActives=async ()=>{
            try {
                const res=await fetch(`${BASEURL}/active`,{
                    method:"GET",
                    headers:{
                        "Content-Type":"application/json",
                        Authorization: token
                    }
                });
    
                const data=await res.json();
                if(res.status===401){
                    alert("Please login");
                    props.handleLogout();
                    navigateTo('/');
                  }
                  if(data.length===0){
                      alert("No registrations");
                  }
                else{
    
                    const formattedData = data.map((d) => {
    
                    const dateObject = new Date(d.startDate);
                    const day = dateObject.getDate().toString().padStart(2, '0');
                    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month because getMonth() returns zero-based index
                    const year = dateObject.getFullYear();
    
                    const dateObjectEnd = new Date(d.endDate);
                    const dayy = dateObjectEnd.getDate().toString().padStart(2, '0');
                    const monthh = (dateObjectEnd.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month because getMonth() returns zero-based index
                    const yearr = dateObjectEnd.getFullYear();
    
                    const today = new Date();
                    const time_difference = dateObjectEnd.getTime() - today.getTime();  
      
                    var days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24))+1;  
    
                    return {
                        ...d,
                        startDate: `${day}-${month}-${year}`,
                        //prefDate: dateObject2.toISOString().substring(0, 10),
                        endDate: `${dayy}-${monthh}-${yearr}`,
                        daysLEFT: days_difference
                    };
    
                    });
                    setActive(formattedData);
                    document.querySelectorAll('#myTable tr').forEach(row => {
                        const rowData = row.cells[7].textContent; 
                        if (rowData<10) {
                            row.style.backgroundColor = 'red';
                        }
                    });
                    
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchActives();
    // eslint-disable-next-line
    },[]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
         <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Active Users</h1>
                </div>
      </div>
      <table id='myTable'>
            <thead>
                <tr>
                <th>Id</th>
                <th>Full name</th>
                <th>Address</th>
                <th>Phone no</th>
                <th>Start date</th>
                <th>Choice</th>
                <th>End date</th>
                <th>Days left in a cycle</th>
                <th>Delete</th>
                <th>Details</th>
                </tr>
            </thead>
            <tbody>
                {
                    active.map((elem,index)=>{
                        return <tr key={elem.id}>
                            <td>{elem.id}</td>
                            <td>{elem.fname} {elem.lname}</td>
                            <td>{elem.address}, {elem.postal}</td>
                            <td>{elem.pno}</td>
                            <td>{elem.startDate}</td>
                            <td>{elem.choice==='d'?"Delivery":"Pickup"}</td>
                            <td>{elem.endDate}</td>
                            <td>{elem.daysLEFT}</td>
                            <td><button>Delete</button></td>
                            <td><button data-bs-toggle="modal" data-bs-target="#modalAlert" onClick={()=>showDetails(index)}>Details</button></td>
                            
                        </tr>
                    })
                }
            </tbody>
        </table>
        <div style={{marginTop:"20px"}}>
        </div>
        <div className="modal fade" id="modalAlert" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Details {}</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className='row'>User Id: {selectedUser && `${selectedUser.id}`}</div>
        <div className='row'>Full name: {selectedUser && `${selectedUser.fname} ${selectedUser.lname}`}</div>
        <div className='row'>Email address: {selectedUser && `${selectedUser.email}`}</div>
        <div className='row'>Home Address: {selectedUser && `${selectedUser.address} ${selectedUser.postal}`}</div>
        <div className='row'>Phone number: {selectedUser && `${selectedUser.pno}`}</div>
        <div className='row'>Alternate Phone number: {selectedUser && `${selectedUser.apno}`}</div>
        <div className='row'>Start date: {selectedUser && `${selectedUser.startDate}`}</div>
        <div className='row'>Choice: {selectedUser && `${selectedUser.choice==='d'?"Delivery":"Pickup"}`}</div>        
                
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
        </div>

    </div>
    </div>
  )
}

export default ActiveUsers
