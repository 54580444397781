import React,{useState} from 'react'

const CreateStudent=()=> {
    const [student,setStudent] = useState({});
    const BASEURL = process.env.REACT_APP_BASE_URL;

    let name,value;
    const handleInputs = (e)=>{
        e.preventDefault();
        name=e.target.name;
        value=e.target.value;
        console.log(value);

        setStudent({...student,[name]:value});
    }

    const postData=async (e)=>{
        e.preventDefault();
        const {Id,Name,Email}=student;

        try {
            const res=await fetch(`${BASEURL}/create`,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    Id,Name,Email
                })
            });

            await res.json();

            if(res.status===201){
                setStudent({});
                alert("added the student!");
            }
            else{
                console.log("error!");
            }
        } catch (error) {
            console.log("error: ",error);
        }
    }
  return (
    <div style={{marginTop:"100px"}}>
       <form>
        <label>Id: </label>
        <input type='text' id="Id" name="Id" value={student.Id} onChange={handleInputs}/>
        <label>Name: </label>
        <input type='text' id="Name" name="Name" value={student.Name} onChange={handleInputs}/>
        <label>Email: </label>
        <input type='text' id="Email" name="Email" value={student.Email} onChange={handleInputs}/>
        <button type='submit' id='submit' onClick={postData}>Submit</button>
      </form>
    </div>
  )
}

export default CreateStudent
