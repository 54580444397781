import './App.css';
import {useState } from 'react';
import {Routes,Route, useNavigate} from 'react-router-dom';
import Student from './components/Student';
import UpdateStudent from './components/UpdateStudent';
import CreateStudent from './components/CreateStudent';
import Tiffin from './components/Tiffin';
import Login from './components/Login';
import Register from './components/Register';
import Admin from './components/Admin';
import ActiveUsers from './components/ActiveUsers';
import UserProfile from './components/UserProfile';
import Registrations from './components/Registrations';
import Navbar from './components/Navbar';
import Menu from './components/Menu';
import TiffinGraph from './components/TiffinGraph';
import UserHome from './components/UserHome';
import Footer from './components/Footer';
import Error from './components/Error';

const App=()=> {
  const [auth, setAuth] = useState(localStorage.getItem("TOKEN"));
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
  const [currentPage, setCurrentPage] = useState('home');
  const [showSpinner, setShowSpinner] = useState(false);

  const hideFooterPaths = ['']; // for footer exclusion
  const navigateTo=useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigateTo("/");
    window.location.reload();
  };
  
  return (
    <div className="App">
      <Navbar handleLogout={handleLogout} currentPage={currentPage} showSpinner={showSpinner}/> 
      <Routes>
        <Route path='/' element={<UserHome showSpinner={showSpinner} setShowSpinner={setShowSpinner}/>}></Route>
        <Route path='/create' element={<CreateStudent />}></Route>
        <Route path='/records' element={<Student />}></Route>
        <Route path='/update/:idNo' element={<UpdateStudent />}></Route>
        <Route path='/userProfile' element={!localStorage.getItem("TOKEN")? <Error/>:<UserProfile />}></Route>
        <Route path='/tiffin' element={<Tiffin />}></Route>
        <Route path='/login' element={localStorage.getItem("TOKEN")? <Error/>:<Login setAuth={setAuth} setUserRole={setUserRole}/>}></Route>
        <Route path='/register' element={localStorage.getItem("TOKEN")? <Error/>:<Register setAuth={setAuth}/>}></Route>
        <Route path='/admin' element={<Admin />}></Route>
        <Route path='/registrations' element={<Registrations handleLogout={handleLogout}/>}></Route>
        <Route path='/active' element={<ActiveUsers handleLogout={handleLogout}/>}></Route>
        <Route path='/menu' element={<Menu/>}></Route>
        <Route path='/tiffinGraph' element={<TiffinGraph/>}></Route>
        <Route path='*' element={<Error/>}></Route>
      </Routes>
      <Footer showSpinner={showSpinner} setShowSpinner={setShowSpinner}/>
    </div>
  );
}

export default App;