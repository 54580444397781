import React,{useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

const UpdateStudent=()=> {
  const [student,setStudent] = useState({});

  const BASEURL = process.env.REACT_APP_BASE_URL;

  const location = useLocation()
  const { idNo} = location.state;
  
  const getData=async ()=>{
   
    try {
      const res=await fetch(`${BASEURL}/profile/${idNo}`,{
        method:"GET",
        headers:{
          "Content-Type":"application/json"
        }
      });

      const data=await res.json();
      // const single = data[0];
      // console.log("data in getdata: ",data)
      // console.log("single after fetch:  ",single)

      if(res.length===0){
        console.log("No data fetch possible");
      }else{
              setStudent(data);

          setTimeout(() => {
          }, 400);
          
      }
    } catch (error) {
      console.log(error);
    }
  }

  let name,value;
  const handleInputs=(e)=>{
    e.preventDefault();
    name=e.target.name;
    value=e.target.value;

    setStudent({...student,[name]:value});
  }

  const postData=async (e)=>{
    e.preventDefault();
    const {Name,Email}=student;

    try {
      const res=await fetch(`${BASEURL}/update/${idNo}`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify({
          Name,Email
        })
      });

      const data=await res.json();
    
      if(res.status===201){
          setStudent(data);
          window.location.reload();
          alert("user updated");
      }
      else{
        alert("No update possible");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getData();
    // eslint-disable-next-line
  },[]);


  return (
    <div>
      <h1>Update {idNo}</h1>
      {
        student && student!==undefined && student!==null && 
      <form>
          <div>
        <h2>name: {student!==null && student!==undefined && student.Name}</h2>
        <label htmlFor='Id'>Id: </label>
        <input type="text" name="Id" id="Id" defaultValue={(student && student!==undefined && student!==null && student.Id) ?? ''} readOnly={true}/>
        <label htmlFor='Name'>Name: </label>
        <input type="text" name="Name" id="Name" placeholder={(student && student!==undefined && student!==null && student.Name) ?? ''} onChange={handleInputs}/>
        <label htmlFor='Email'>Email: </label>
        <input type="text" name="Email" id="Email" placeholder={(student && student!==undefined && student!==null && student.Email) ?? ''} onChange={handleInputs}/>
        <button type='submit' id='submit' onClick={postData}>Submit</button>
        </div>
        
      </form>
    }
    </div>
  )
}

export default UpdateStudent
