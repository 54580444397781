const checkNetworkSpeed = (callback) => {
    const image = new Image();
    const startTime = new Date().getTime();
  
    image.onload = () => {
      const endTime = new Date().getTime();
      const duration = endTime - startTime;
      const speed = (500000 / duration) * 1000; // Speed in bits per second
      callback(speed);
    };
  
    image.onerror = () => {
      // If the image fails to load, assume no internet connection
      callback(0);
    };
  
    image.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Pizigani_1367_Chart_1MB.jpg/1200px-Pizigani_1367_Chart_1MB.jpg?20110407062122"; // Replace with a 1MB image
  };
  
  const checkNetworkStatus = (callback) => {
    if (!navigator.onLine) {
      callback(0); // No internet connection
    } else {
      checkNetworkSpeed(callback);
    }
  };
  
  export default checkNetworkStatus;