import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Registrations=(props)=> {
    const BASEURL=process.env.REACT_APP_BASE_URL;
    const [registered,setRegistered] = useState([]);
    const [selectedUser,setSelectedUser] = useState({});
    const [dateUser,setDateUser] = useState({});
    const [searchTerm,setSearchTerm] = useState('');
    const [displayedRegistrations, setDisplayedRegistrations] = useState([]);
    const navigateTo=useNavigate();

    // if(token){
      
    // }

    // const fetchRegistrations=async ()=>{
    //     try {
    //         const res=await fetch(`${BASEURL}/registrations`,{
    //             method:"GET",
    //             headers:{
    //                 "Content-Type":"application/json",
    //                 Authorization: token
    //             }
    //         });

    //         const data=await res.json();
    //         console.log("res.status: ",res.status); //401
    //         if(res.status===401){
    //           alert("Please login");
    //           props.handleLogout();
    //           navigateTo('/login');
    //         }
    //         if(data.length===0){
    //             alert("No registrations");
    //         }
    //         else{
    //             const formattedData = data.map((d) => {

    //             //converting from yyyy-mm-dd to dd-mm-yyyy
    //             const dateObject = new Date(d.startDate);
    //             const day = dateObject.getDate().toString().padStart(2, '0');
    //             const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); 
    //             const year = dateObject.getFullYear();

    //             const dateObject2 = new Date(d.prefDate);

    //             return {
    //                 ...d,
    //                 startDate: `${day}-${month}-${year}`,
    //                 prefDate: dateObject2.toISOString().substring(0, 10)
    //             };
                              
    //         });
    //         setRegistered(formattedData);
    //         setDisplayedRegistrations(formattedData);

    //         displayedRegistrations.forEach(dis=>{
    //           console.log("dissS: ",dis);
    //         })
            
    //     }
    //     } catch (error) {
    //         console.log("error: ",error);
    //     }
    // }

    const activateUser=async (userId,index)=>{

        //const {fname,lname,email,address,postal,pno,apno,prefDate,choice,pwd} = registered[index];
        const {fname,lname,email,address,postal,pno,apno,prefDate,choice,pwd} = displayedRegistrations[index];
      
        const dateObject=new Date(prefDate);

         //pref = start date
        const formattedDate = dateObject.toISOString().substring(0, 10) 
        let endDate=new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, dateObject.getDate())

        //end date
        const formattedDate2 = endDate.toISOString().substring(0, 10);
       
        try {
            const res=await fetch(`${BASEURL}/users`,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    userId,fname,lname,email,address,postal,pno,apno,formattedDate,formattedDate2,choice,pwd
                })
            });
          
        console.log("res : ",res);
        if(res.status===201){
            //will think on folllowing
            //setRegistered(prevReg => prevReg.filter(registered => registered.id !== userId));
            alert("Activated!");

        }
        else if(res.status===501){
            alert("Cannot activate!");
        }
        } catch (error) {
            console.log(error);
        }        
    }

    const showDetails=(index)=>{
        setSelectedUser(displayedRegistrations[index]);
    }

    const handlePrefDate=(index,id)=>{
        setDateUser(displayedRegistrations[index]);
    }

    const postNewDate=async (id,prefDate)=>{

      const dateObject=new Date(prefDate);
      let endDate=new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, dateObject.getDate());
      const formattedEndDate = endDate.toISOString().substring(0, 10);

      try {
        const res=await fetch(`${BASEURL}/upDate`,{
          method:"POST",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({
            id,prefDate,formattedEndDate
          })
        });

      await res.json();

      if(res.status===201){
        const updatedRegistered = registered.map(user => {
          if (user.id === id) {
              return {...user, prefDate,formattedEndDate};
          }
          return user;
      });
      alert("Preferrence date updated!");
      setRegistered(updatedRegistered);
      setDisplayedRegistrations(updatedRegistered);
      }
      else{
        alert("Preferrence date connot be updated!");
      }
      } catch (error) {
        console.log("error : ",error);
      }
  }

  const deleteRecord=async (userId)=>{
    try {
      const res=await fetch(`${BASEURL}/deleteRecord/${userId}`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        }
      });
      if(res.status===201){
        setDisplayedRegistrations(prev => prev.filter(displayedRegistrations => displayedRegistrations.id !== userId));
        setRegistered(prev => prev.filter(registered => registered.id !== userId));
        alert("Deleted !!");
      }
      else{
        alert("cannot delete!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePrefDateChange = (e) => {
    const { name, value } = e.target;
    setDateUser({...dateUser, [name]: value});
}

const handleChange = (e) => {
  const { value } = e.target;
  setSearchTerm(value);

  if (value === '') {
      setDisplayedRegistrations(registered);
  } else {
      const filteredRegistrations = registered.filter(user => {
          // Perform case-insensitive search by converting both the user's name and the search term to lowercase
          const fullName = `${user.fname} ${user.lname}`.toLowerCase();
          const searchTermLower = value.toLowerCase();
          return fullName.includes(searchTermLower);
      });
      // Update the displayed registrations with the filtered data
      setDisplayedRegistrations(filteredRegistrations);
  }
};


useEffect(()=>{
  const token = localStorage.getItem("TOKEN");
 
    const fetchRegistrations=async ()=>{
      try {
          const res=await fetch(`${BASEURL}/registrations`,{
              method:"GET",
              headers:{
                  "Content-Type":"application/json",
                  Authorization: token
              }
          });
  
          const data=await res.json();
          console.log("res.status: ",res.status); //401
          if(res.status===401){
            alert("Please login");
            props.handleLogout();
            navigateTo('/');
          }
          if(data.length===0){
              alert("No registrations");
          }
          else{
              const formattedData = data.map((d) => {
  
              //converting from yyyy-mm-dd to dd-mm-yyyy
              const dateObject = new Date(d.startDate);
              const day = dateObject.getDate().toString().padStart(2, '0');
              const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); 
              const year = dateObject.getFullYear();
  
              const dateObject2 = new Date(d.prefDate);
  
              return {
                  ...d,
                  startDate: `${day}-${month}-${year}`,
                  prefDate: dateObject2.toISOString().substring(0, 10)
              };
                            
          });
          setRegistered(formattedData);
          setDisplayedRegistrations(formattedData);
  
          displayedRegistrations.forEach(dis=>{
            console.log("dissS: ",dis);
          })
          
      }
      } catch (error) {
          console.log("error: ",error);
      }
  }
          fetchRegistrations();
  
  
 
        // eslint-disable-next-line
    },[]);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div>
      <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Tiffin Registration</h1>
                </div>
      </div>
      <h4>Search registered users</h4>

      <div className='container' style={{marginBottom:"30px"}}>
        <div className='row justify-content-center'>
          <div className='col-md-6'>
          <input type="search" name="searchTerm" className="form-control rounded" placeholder="Search user" aria-label="Search" aria-describedby="search-addon"
          value={searchTerm} onChange={handleChange}/>
          {/* {(e)=>setSearchTerm(e.target.value)} */}
          </div>
        </div>

      </div>

        <table>
            <thead>
                <tr>
                <th>Id</th>
                <th>Full name</th>
                <th>Address</th>
                <th>Phone no</th>
                <th>Start date</th>
                <th>Choice</th>
                <th>Preferred date</th>
                <th>Activate</th>
                <th>Details</th>
                <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {
                    //instead of registered 
                    displayedRegistrations.map((elem,index)=>{
                        return <tr key={elem.id}>
                            <td>{elem.id}</td>
                            <td>{elem.fname} {elem.lname}</td>
                            <td>{elem.address}, {elem.postal}</td>
                            <td>{elem.pno}</td>
                            <td>{elem.startDate}</td>
                            {/* <td>{elem.pwd}</td> */}
                            <td>{elem.choice==='d'?"Delivery":"Pickup"}</td>
                            <td><input type='date' name="prefDate1" value={elem.prefDate} data-bs-toggle="modal" data-bs-target="#modalAlert2" onClick={()=>handlePrefDate(index,elem.id)} readOnly></input></td>
                            <td><button onClick={()=>activateUser(elem.id,index)}>Activate</button></td>
                            <td><button data-bs-toggle="modal" data-bs-target="#modalAlert" onClick={()=>showDetails(index)}>Details</button></td>
                            <td><button onClick={()=>deleteRecord(elem.id)}>Delete</button></td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    <div className="modal fade" id="modalAlert" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Details {}</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className='row'>User Id: {selectedUser && `${selectedUser.id}`}</div>
        <div className='row'>Full name: {selectedUser && `${selectedUser.fname} ${selectedUser.lname}`}</div>
        <div className='row'>Email address: {selectedUser && `${selectedUser.email}`}</div>
        <div className='row'>Home Address: {selectedUser && `${selectedUser.address} ${selectedUser.postal}`}</div>
        <div className='row'>Phone number: {selectedUser && `${selectedUser.pno}`}</div>
        <div className='row'>Alternate Phone number: {selectedUser && `${selectedUser.apno}`}</div>
        <div className='row'>Preffered date: {selectedUser && `${selectedUser.prefDate}`}</div>
        <div className='row'>Choice: {selectedUser && `${selectedUser.choice==='d'?"Delivery":"Pickup"}`}</div>        
                
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
    </div>

    
    {/* Modal for changing preferred date*/}
    <div className="modal fade" id="modalAlert2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Details {}</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className='row'>User Id: {dateUser && `${dateUser.id}`}</div>
        <div className='row'>Full name: {dateUser && `${dateUser.fname} ${dateUser.lname}`}</div>        
        <div className='row'>Previous preferred date: {dateUser && `${dateUser.prefDate}`}</div>  
        {/* the above can be changed too */}
        <div>New preferred date: <input type='date' name="prefDate" value={dateUser && `${dateUser.prefDate}`} onChange={(e)=>handlePrefDateChange(e)}></input></div>      
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>postNewDate(dateUser.id,dateUser.prefDate)}>Save</button> */}
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>postNewDate(dateUser.id,dateUser.prefDate)}>Activate</button>
        {/* onClick={()=>setDateUser(console.log(dateUser.prefDate))} */}
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
        </div>
    </div>
    </div>
  )
}

export default Registrations
