import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

const Student=()=> {
  const [student,setStudent]=useState([]);
  const BASEURL = process.env.REACT_APP_BASE_URL;

  const getStudents =async ()=>{
    
    try {
      const res=await fetch(`${BASEURL}/records`,{
        method:"GET",
        headers:{
          "Content-Type":"application/json"
        }
      });
      const result=await res.json();
      if (result.length === 0) {  //res.status === 201
        setTimeout(()=>{    
            alert("No results found!!");
        },200);
    } else {
        // setTimeout(() => {
          setStudent(result);
       // }, 1000);
    }
    } catch (error) {
     console.log(error); 
    }
  }

  const onDelete=async (studentId)=>{
    try {
      const res=await fetch(`${BASEURL}/delete/${studentId}`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        }
      });

      await res.json();
      if(res.status===201){
        setStudent(prevStudents => prevStudents.filter(student => student.Id !== studentId));
      }else{
        console.log("cannot remove");
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    getStudents();
    // eslint-disable-next-line
  },[]);
  
  return (
    <div style={{marginTop:"100px"}}>
      <h1>student</h1>
      <table>
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
        </thead>
        <tbody>
        { 
         student.map((elem)=>{
           return <tr key={elem.Id}>
             <td>Id: {elem.Id}</td>
             <td>{elem.Name}</td>
             <td>{elem.Email}</td>
             <td><Link to={`/update/${elem.Id}`} state={{ idNo: elem.Id }}><button>Update</button></Link></td>
             <td><button onClick={()=>onDelete(elem.Id)}>Delete</button></td>
           </tr> 
           })
        }
        </tbody>
        
        
      </table>
    </div>
  )
}

export default Student
