import React,{useEffect,useRef,useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const Admin=()=> {

const BASEURL=process.env.REACT_APP_BASE_URL;
const [yesUsers,setYesUsers]=useState([]);
const [noUsers,setNoUsers]=useState([]);
const [weekStartDate, setWeekStartDate] = useState(null);
const [weekEndDate, setWeekEndDate] = useState(null);
const [menu,setMenu]=useState();
const [menuDates,setMenuDates]=useState([]);
const today=new Date().toDateString();
console.log("todaYYYYYYYY: ",today)
const pdfRef=useRef();
const navigateTo=useNavigate();
const token = localStorage.getItem("TOKEN");

const handleLogout = () => {
    localStorage.clear();
    navigateTo("/");
    window.location.reload();
};

const downloadPDF=()=>{
    const input = pdfRef.current;
    html2canvas(input).then((canvas)=>{
        const imgData=canvas.toDataURL('image/png');
        const pdf=new jsPDF('p','mm','a4',true);
        const pdfWidth=pdf.internal.pageSize.getWidth();
        const pdfHeight=pdf.internal.pageSize.getHeight();
        const imgWidth=canvas.width;
        const imgHeight=canvas.height;
        const ratio=Math.min(pdfWidth/imgWidth,pdfHeight/imgHeight);
        const imgX=(pdfWidth-imgWidth*ratio)/2;
        const imgY=30;
        pdf.addImage(imgData,'PNG',imgX,imgY,imgWidth*ratio,imgHeight*ratio);
        pdf.save(`${today}'s deliveries.pdf`);
    })
}

const formatDate=(dateString)=>{
const originalDate = new Date(dateString);

const year = originalDate.getFullYear();
const month = ("0" + (originalDate.getMonth() + 1)).slice(-2); // Months are zero-based
const day = ("0" + originalDate.getDate()).slice(-2);

const newDateString = year + "-" + month + "-" + day;
return newDateString; 
}

const checkIfEndDate=async ()=>{

const todaysDate=formatDate(today);
try {
    const res=await fetch(`${BASEURL}/retention`,{
        method:"POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({todaysDate})
    });
    await res.json()
    if(res.status===201){
        alert("doneeeeeee")
    }
    else{
        alert("error!")
    }
} catch (error) {
    console.log("error")
}
}

const deleteFromUSers=async()=>{
    const todaysDate=formatDate(today);
    if(todaysDate){
        const customHeaders={
            "Todate":todaysDate
        }
        try {
            const res=await fetch(`${BASEURL}/deleteFromUsers`,{
                method:"GET",
                headers: customHeaders
            });
            await res.json()
            if(res.status===201){
                alert("doneeeeeee")
            }
            else{
                alert("error!")
            }
        } catch (error) {
            console.log("error")
        }
    }

}

let todaysDate;
const reverseNO=async (userid)=>{
    if(new Date(today).getDay()===0){
        const monday=new Date(today);
        monday.setDate(monday.getDate()+1);
        todaysDate=formatDate(monday);
    }else{
        todaysDate=formatDate(today);
    }
    //const todaysDate=formatDate(today);
    if(todaysDate){
        const customHeaders = {
            "Content-Type": "application/json",
            "Todate": todaysDate,
            "UserID":userid,
            Authorization:token
        };
try {
    const res=await fetch(`${BASEURL}/reverseNo`,{
        method:"GET", 
        headers:customHeaders
    });
    await res.json();
    if(res.status===201){
        noUsers.filter(usr => usr.id!==userid);
        console.log("no user:",noUsers)
        alert("Changes saved!")
        window.location.reload();
      }
      else if(res.status===401){
        alert("Please login");
        handleLogout();
        navigateTo('/');
      }else{
        alert("Changes could not be saved!")
      }
} catch (error) {
    console.log("error!")
}
}
}

const reverseYES=async (userID)=>{
    console.log("reached in reverse yes!")
    if(new Date(today).getDay()===0){
        const monday=new Date(today);
        monday.setDate(monday.getDate()+1);
        todaysDate=formatDate(monday);
        console.log("todays date: ",todaysDate)
    }else{
        todaysDate=formatDate(today);
    }
    
    // const todaysDate=formatDate(today);
    const res=await fetch(`${BASEURL}/reverseYES`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          Authorization:token
        },
        body:JSON.stringify({
            todaysDate,userID
        })
      })
      if(res.status===201){
        console.log("reached in 201!")
        yesUsers.filter(usr => usr.id!==userID);
        alert("Changes saved!")
        window.location.reload();
      }
      else if(res.status===401){
        console.log("reached in 401!")
        alert("Please login");
        handleLogout();
        navigateTo('/');
      }else{
        console.log("reached in else!")
        alert("Changes could not be saved!")
      }
}

const checkFormat=async ()=>{
    const res=await fetch(`${BASEURL}/checkFormat`,{
        method:"GET",
        headers:{
          "Content-Type":"application/json"
        }
      })
      const data = await res.json();
      if(res.status===201){
        console.log("end date: ",data);
      }else{
        console.log("not STORED!!");
      }
}

const getTodaysData=async ()=>{

    //getting today's date
    const nowDate = new Date();
    console.log("nowdae: ",nowDate)

    //next day's date
    const nextDate = new Date(nowDate);

    let dateToSend;
    
    const year = nowDate.getFullYear();
    const month = ('0' + (nowDate.getMonth() + 1)).slice(-2); 
    const day = ('0' + nowDate.getDate()).slice(-2);
    
    const formattedDate = `${year}-${month}-${day}`;

    const nowDay=new Date(formattedDate);

    console.log("nowDay :",nowDay)
    console.log("nowDay.getDay() :",nowDay.getDay())
    if(formattedDate && nowDay.getDay()===6){
        
        //dateToSend.setDate(nowDate.getDate() + 1);
        nextDate.setDate(nowDate.getDate() + 1);
        const year = nextDate.getFullYear();
        const month = String(nextDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(nextDate.getDate()).padStart(2, '0');
        dateToSend = `${year}-${month}-${day}`;

        // dateToSend = nextDate.toISOString().split('T')[0];  NEVER EVER DO THIS, IT WILL CHANGE THE DATE TO THE NEXT DAY DUE TO THE 
        //TIMEZONE SHIT 
        
        console.log("nextDate: ",nextDate)
        console.log("dateToSend in if block: ",dateToSend)

    }
    else if(nowDay.getDay()!==6){
        dateToSend=formattedDate;
    }
    console.log("date to send : ",dateToSend)

    if(dateToSend!==null){ 
        const customHeaders = {
            "Content-Type": "application/json",
            "Todate": dateToSend //formatteddate
        };
        //fetching YES's
        try {
            const res=await fetch(`${BASEURL}/todaysYes`,{
                method:"GET",
                headers:customHeaders
            });
        
            if (res.status !== 201) {
                throw new Error("Failed to fetch today's canceled dates");
            }
            const data=await res.json();
            console.log("data: ",data);
            if(data.length===0){
                setYesUsers([])
            }
            else{
                setYesUsers(data);
            }
        } catch (error) {
            console.log("error in catch of yes users: ",error);
        }
        //fetching NO's
        try {
            const res2=await fetch(`${BASEURL}/todaysNo`,{
                method:"GET",
                headers:customHeaders
            });
            if (res2.status !== 201) {
                throw new Error("Failed to fetch today's canceled dates");
            }
        
            const data2=await res2.json();
            if (data2.length === 0) {
                setNoUsers([]);
            } else {
                console.log("some dates today!");
                setNoUsers(data2);
            }
        } catch (error) {
        }
        
    }
    else{
        setYesUsers([])
        setNoUsers([])
    }
     
} 
useEffect(()=>{ 
getTodaysData();
},[]);

useEffect(() => {
    const getMenu = async () => {
        const today = new Date();
        const startOfWeek = new Date(today);
        const endOfWeek = new Date(today);

        startOfWeek.setDate(today.getDate() - today.getDay() + 1);
        endOfWeek.setDate(today.getDate() - today.getDay() + 6); 

        const formattedStartDate = formatDate(startOfWeek);
        const formattedEndDate = formatDate(endOfWeek);

        setWeekStartDate(formattedStartDate);
        setWeekEndDate(formattedEndDate);

        if (formattedStartDate && formattedEndDate) {
            const customHeaders2 = {
                "Content-Type": "application/json",
                "StartDate": formattedStartDate,
                "EndDate": formattedEndDate,
            };

            try {
                const res = await fetch(`${BASEURL}/fetchMenu`, {
                    method: "GET",
                    headers: customHeaders2
                });
            
                const data = await res.json();
                if (data.length === 0) {
                    console.log("No tiffin yet!");
                    setMenu([]);
                } else if (data.length > 1) {
                    const formattedMenuDates = data.map((d) => {
                        const dateObject = new Date(d.meal_date);
                        return dateObject.toLocaleDateString('en-US', {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        });
                    });
                    setMenuDates(formattedMenuDates);
                    setMenu(data);
                }
            } catch (error) {
                console.log("error in catch of yes users: ", error);
            } 
        }
    };
    getMenu();
    // eslint-disable-next-line
}, []);

useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
        <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Welcome Admin</h1>
                </div>
      </div>

        <div className='container'>
        <div className='row'>
            <div className='col-12'>
                <button onClick={checkIfEndDate}>check for cycles</button>
            </div>
            <div className='col-12'>
                <button onClick={deleteFromUSers}>check for endings</button>
            </div>
            <div className='col-12'>
                <button onClick={checkFormat}>check formats</button>
            </div>
            </div>
            <div className='row'>
            <div className='col-12'>
                    <button><NavLink to='/menu'>Add Tiffin</NavLink></button>
            </div>
            </div>
            <div className='row'>
            <div className='col-12'>
                <h3>This week's Tiffin</h3>
                {
                menu && menu.length === 0 ? (
                <h6>No Tiffin yet!!</h6>       
                     ) : (
                <table>
                <thead>
                <tr>
                <th>Meal</th>
                <th>Meal date</th>
                </tr>
            </thead>
            <tbody>
                
            {
                    menu && menu.map((elem,index)=>{
                        return <tr key={elem.meal_id}>
                            <td>{elem.meal}</td>
                            {
                        menuDates && menuDates.map((dateElem, dateIndex)=>{
                            if (dateIndex === index) {
                                return <td key={dateIndex}>{dateElem}</td>;
                            }
                            return null;
                })
            }
                            </tr>
                    })
                }
           
                </tbody>
                </table>
            )}
            </div>
            </div>
            <div className='row' >
                <div className='col-12'>
                    Today's YES'es <button onClick={downloadPDF}>Print</button>
        {
        yesUsers && yesUsers.length === 0 ? (
            <h6>No YES today, maybe Sunday it is!!</h6>       
        ) : (
                    <table id='myTable' ref={pdfRef}>
            <thead>
                <tr>
                <th>Id</th>
                <th>Full name</th>
                <th>Address</th>
                <th>Phone no</th>
                <th>Choice</th>
                <th>Make it a No</th>
                </tr>
            </thead>
            <tbody>
                {
                    yesUsers && yesUsers.map((elem,index)=>{
                        return <tr key={elem.id}>
                            <td>{elem.id}</td>
                            <td>{elem.fname} {elem.lname}</td>
                            <td>{elem.address}</td>
                            <td>{elem.pno}</td>
                            <td>{elem.choice==='d'?"Delivery":"Pickup"}</td>
                           
                            <td><button onClick={()=>reverseYES(elem.id)}>Make it a No</button></td>                            
                        </tr>
                    })
                }
            </tbody>
        </table>
        )}
                </div>
            </div>
            <div className='row'>
    <div className='col-12'>
        Today's NO'es
        {
        noUsers && noUsers.length === 0 ? (
            <h6>No nos today!!</h6>       
        ) : (
            <table id='myTable'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Full name</th>
                        <th>Address</th>
                        <th>Phone no</th>
                        <th>Choice</th>
                        <th>Make it a yes</th>
                    </tr>
                </thead>
                <tbody>
                    {noUsers && noUsers.length>=1 && noUsers.map((elem, index) => (
                        <tr key={elem.id}>
                            <td>{elem.id}</td>
                            <td>{elem.fname} {elem.lname}</td>
                            <td>{elem.address}</td>
                            <td>{elem.pno}</td>
                            <td>{elem.choice === 'd' ? "Delivery" : "Pickup"}</td>
                            <td><button onClick={()=>reverseNO(elem.id)}>Make it a yes</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
    </div>
</div>
            <div className='row'>
                <div className='col-12'>
                    Data Analytics  :: ====
                    <button><NavLink to='/tiffinGraph'>Tiffin wise</NavLink></button>
                </div>
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default Admin
