import { toLineHeight } from 'chart.js/helpers';
import React, { useState,useEffect } from 'react';
import {useNavigate } from 'react-router-dom';

//calculate new end date every time user or admin canceles the tiffin
//cancel button only enable for current week

const Tiffin = () => {
  const [inputDate, setInputDate] = useState('');
  const [endDateFromDB,setEndDateFromDB] = useState('');
  const [subscriptionDates, setSubscriptionDates] = useState([]);
  const [counter,setCounter] = useState([]);
  const [reverseCounter,setReverseCounter] = useState();
  const [cancelDate,setCancelDate] = useState('');
  const [canceledDates,setCanceledDates]=useState([]);
  const [user,setUser] = useState();
  const [thisWeek,setThisWeek]=useState([]);
  const [buttonStates,setButtonStates]=useState({});
  const [menu,setMenu]=useState();
  
  const BASEURL=process.env.REACT_APP_BASE_URL;
  const token=localStorage.getItem("TOKEN");

  //geting current and next date
  var currentDate = new Date();

  var currentHour = currentDate.getHours();
  var isBeforeTenPM = currentHour < 22; //before 10 pm
  
  const navigate=useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const storeButtonStates=async (date,id,ifCanceled,ifCanReverse)=>{

  const dateObject= new Date(date);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
  const day = String(dateObject.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  console.log("formattedDate: ",formattedDate)

    const res=await fetch(`${BASEURL}/storeButtonStates`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        Authorization:token
      },
      body:JSON.stringify({
        formattedDate,id,ifCanceled,ifCanReverse
      })
    })
    await res.json();
    if(res.status===201){
      console.log("STORE SUCCESS in button state");
    }else if(res.status===401){
      alert("please login")
      handleLogout();
      console.log("not STORED!!");
    }else{
      alert("sone error")
    }
  }
  
  const cancelBtn=async (dateToRemove)=>{

    const currentDate = resetTimeToMidnight(new Date()); // Reset current date to midnight
    const removedDateMidNight=resetTimeToMidnight(dateToRemove);

    // if(new Date(dateToRemove).getTime()===prevDate.getTime() && !isBeforeTenPM){
    //   alert("Not possible to reverse the tiffin after 10!")
    // }
    if(currentDate.getTime()===removedDateMidNight.getTime() && !isBeforeTenPM){
      alert("Not possible to reverse the tiffin after 10!")
    }
    else{
      if(counter.length<4){

        const newCounts=[...counter];
        newCounts.push(counter.length+1);
  
        setCounter(newCounts);
        setReverseCounter(prevValue=>prevValue-1);

        const updatedBtnStates={
          ...buttonStates,
          [dateToRemove]:{isCanceled:true,canReverse:true}
        };
        setButtonStates(updatedBtnStates);
    
        //const updatedSubscriptionDates = subscriptionDates.filter(date => date !== dateToRemove);
        
        let parts = endDateFromDB.split('-');
  
        let newEndDate=new Date(parts[0], parts[1]-1, parts[2]); 
  
        newEndDate.setDate(newEndDate.getDate() + 1);
  
        const today=newEndDate.getDay();
        
        if(today===0){
          newEndDate.setDate(newEndDate.getDate() + 1);
        }
  
        const toSet=newEndDate.toISOString().split('T')[0];
  
        try{
          if(newEndDate){
            await Promise.all([
              setEndDateFromDB(toSet),
              storeEndDate(newEndDate, user.id),
              storeButtonStates(dateToRemove,user.id,true,true)
            ]);

            const updatedSubscriptionDates=[...subscriptionDates,newEndDate.toDateString()]
            setSubscriptionDates(updatedSubscriptionDates);
            //setSubscriptionDates([...subscriptionDates, newEndDate.toDateString()])
            storeInSubs(dateToRemove,newCounts.length);
          }
        }catch(error){
          console.error("Error setting end date or storing end date:", error);
        } 
        
      }
      else{
        alert("your cancellation limit is reached");
      }
    }
   
}

const storeInSubs=async (dateToRemove,counter)=>{
  const {id}=user;
  const dateObject= new Date(dateToRemove);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
  const day = String(dateObject.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  const res=await fetch(`${BASEURL}/storeCanceled`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
      Authorization:token
    },
    body:JSON.stringify({
      formattedDate,id,counter
    })
  })
  await res.json();
  if(res.status===201){
    console.log("STORE SUCCESS");
  }else if(res.status===401){
    //alert("please login")
    handleLogout();
    console.log("not STORED!!");
  }
  else{
    console.log("error")
  }
}

const storeEndDate=async (newEndDate,userId)=>{

  const dateObject=new Date(newEndDate);
  const year = dateObject.getFullYear();
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); 
  const day = ('0' + dateObject.getDate()).slice(-2);

  const formattedEnddate = `${year}-${month}-${day}`;

  try {
    const res=await fetch(`${BASEURL}/updateEndDate`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        Authorization:token
      },
      body:JSON.stringify({
        formattedEnddate,userId
      })
    })
  
    const data=await res.json();
    if(res.status===201){
    console.log("UPDATEDDDDDDDDD!");
    }
    else if(res.status===401){
      //alert("please login")
      handleLogout();
    }
    else if(res.status===501){
      console.log("eoor ")
    }
  } catch (error) {
    console.log("error: ",error)
  }
}
const getCurrentWeekDates = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); 

  const startDate = new Date(currentDate);
  // startDate.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)); // If it's Sunday, go back 6 days
  startDate.setDate(currentDate.getDate() - currentDay); // Go back 'currentDay' days to get to Sunday

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  return { startDate, endDate };
};

let todaysDate;

const reverseNO=async (userid,dateToRemove)=>{
  console.log("dateToRemove: ",dateToRemove) //Sat May 25 2024
  let prevDay=new Date(dateToRemove)
  var prevDate= new Date(prevDay.setDate(prevDay.getDate()-1))

  if(new Date(dateToRemove).getTime()===prevDate.getTime() && !isBeforeTenPM){
    alert("Not possible to reverse the tiffin after 10!")
  }
  else{
const dateObject=new Date(dateToRemove);
const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
  const day = String(dateObject.getDate()).padStart(2, '0');

  const formattedDateToRemove = `${year}-${month}-${day}`;
  console.log("formattedDateToRemove : ",formattedDateToRemove) 


     if(new Date(currentDate).getDay()===0){
        todaysDate=dateObject.setDate(dateObject.getDate()+1);
        console.log("if part sat : ",todaysDate)
    }else{
    const dateObject= new Date(dateToRemove);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
    const day = String(dateObject.getDate()).padStart(2, '0');

    todaysDate = `${year}-${month}-${day}`;
    console.log("else pmart sat : ",todaysDate)
      }
    if(todaysDate){
        const customHeaders = {
            "Content-Type": "application/json",
            "Todate": todaysDate,
            "UserID":userid,
            Authorization:token
        };
try {
    const res=await fetch(`${BASEURL}/reverseNo`,{
        method:"GET", 
        headers:customHeaders
    });
    const data=await res.json();
    if(data.length===0 && res.status===201){
      setReverseCounter(prevValue=>prevValue+1);
      const updatedBtnStates={
        ...buttonStates,
        [dateToRemove]:{isCanceled:false,canReverse:false}
      };
      setButtonStates(updatedBtnStates);
    }
    else if(res.status===501){
        alert("Some problem here, contact admin!")
    }
      else if(res.status===401){
        //alert("Please login");
        handleLogout();
        navigate('/');
      }
} catch (error) {
    console.log("error!")
}
}
  }
}

const fetchButtonStates=async (userID)=>{
  const customHeaders = {
    "Content-Type": "application/json",
    "UserID":userID,
    Authorization:token
  };
  const res=await fetch(`${BASEURL}/fetchButtonStates`,{
    method:"GET", 
    headers:customHeaders
  });
  const data=await res.json();
  if(res.status===201 && data.length>=1){
      const states = {};

      data.forEach(item => {
        states[item.date] = {
          isCanceled: item.isCanceled,
          canReverse: item.canReverse
        };
      });

      console.log("states: ", states);
      setButtonStates(states); 
  }
  else if(res.status===401){
    //alert("Please login");
    handleLogout();
    navigate('/');
  }else if(res.status===501){
    console.log("Changes could not be saved!")
  }else if(res.status===201 && data.length===0){
    setButtonStates({});
  }
}

const formatDate=(dateString)=>{
  const originalDate = new Date(dateString);
  
  const year = originalDate.getFullYear();
  const month = ("0" + (originalDate.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ("0" + originalDate.getDate()).slice(-2);
  
  const newDateString = year + "-" + month + "-" + day;
  return newDateString; 
}
// Helper function to reset the time portion of a Date object to midnight
const resetTimeToMidnight = (date) => {
  const newDate = new Date(date); // Create a copy of the date
  newDate.setHours(0, 0, 0, 0);   // Set hours, minutes, seconds, and milliseconds to zero
  return newDate;                 // Return the modified date
};
//gets username and start date
useEffect(()=>{
    const getUsernameAndStartDate=async ()=>{
      try {
        const res=await fetch(`${BASEURL}/userProfile`,{
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            "Authorization":token
          }
        });
  
        const data = await res.json();
        if(res.status===201){
  
          setUser(data);
          await fetchButtonStates(data.id);          
  
          const dateObject = new Date(data.startDate);
          const dateObject2 = new Date(data.endDate);
  
          const formattedDate = dateObject.toLocaleDateString('en-US', { timeZone: 'UTC' }).substring(0, 10);
          const parts = formattedDate.split('/'); // Split the input date string by '/'
          const newformattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
  
          const formattedEndDate = dateObject2.toLocaleDateString('en-US', { timeZone: 'UTC' }).substring(0, 10);
          const partss = formattedEndDate.split('/'); // Split the input date string by '/'
          const newformattedEndDate = `${partss[2]}-${partss[0].padStart(2, '0')}-${partss[1].padStart(2, '0')}`;
  
          //setting both to yyyy-mm-dd format
          setInputDate(newformattedDate);
          setEndDateFromDB(newformattedEndDate);
        }
        else{
          //alert("Please login!")
          handleLogout();
        }
      } catch (error) {
        console.log("error in trycatch: ",error);
      }
}
  getUsernameAndStartDate();
  // eslint-disable-next-line
},[]);

// //gets canceled date 
  useEffect(()=>{
    const getCanceledDate=async ()=>{
      const res=await fetch(`${BASEURL}/canceledDate`,{
        method:"GET",
        headers:{
          "Content-Type":"application/json",
          "Authorization":token
        }
  
      });
      const data = await res.json();
      if(res.status===201){
        setCanceledDates(data);
        const counters = data.map(obj => obj.counter);
        await Promise.all([
          setCounter(counters),
          setReverseCounter(4-counters.length)
        ]);  
      }
      else{
        alert("Please Login!")
        handleLogout();
      }
      
    }
  
   getCanceledDate();
     // eslint-disable-next-line
  },[]);

// //calculates subscription dates
  useEffect(()=>{
    const calculateSubscriptionDates = async () => {

      const dateParts = inputDate.split('-');
      const yearr = parseInt(dateParts[0], 10);
      const monthh = parseInt(dateParts[1], 10) - 1; // Months are zero-based
      const dayy  = parseInt(dateParts[2], 10);
  
      const startDate = new Date(yearr, monthh, dayy);
  
      const datePartss = endDateFromDB.split('-');
      const year = parseInt(datePartss[0], 10);
      const month = parseInt(datePartss[1], 10) - 1; // Months are zero-based
      const day  = parseInt(datePartss[2], 10);
  
      let finalDate = new Date(year,month,day);
  
      const dates = [];
      let currentDate = startDate;
  
      while (currentDate.getTime() <= finalDate.getTime()) {
        const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        if (dayOfWeek >= 1 && dayOfWeek <= 6) { // Monday to saturday
          dates.push(currentDate.toDateString());
        }
        currentDate.setDate(currentDate.getDate()+1); // Move to the next day
      }
  
      setSubscriptionDates(dates);
  
      if(subscriptionDates.length!==0){
        const canceledDateStrings = canceledDates.map(dateObj => new Date(dateObj.canceledDate).toDateString());
        const filteredDatesArray = subscriptionDates && subscriptionDates.filter(date => !canceledDateStrings.includes(date));
        setSubscriptionDates(filteredDatesArray);
      }
     
    };
    if(endDateFromDB !==''){
      calculateSubscriptionDates();
    }
  // eslint-disable-next-line
  },[endDateFromDB,counter]);

// //gets the current week enabled
useEffect(()=>{
// const getCurrentWeekEnabled=()=>{
  
//   const { startDate, endDate } = getCurrentWeekDates();
//   let tempDate = new Date(startDate);
//   console.log("start date this week: ",startDate)
//   console.log("end date this week: ",endDate)
//   console.log("currentDate date this week: ",currentDate)

//   const dates = [];

//     while (tempDate.getTime() <= endDate.getTime()) {

//       if(currentDate<tempDate){ //these will be future dates
//         console.log("tempDate: ",tempDate)
//         console.log("currentDate: ",currentDate)

//         if(tempDate.getTime()!==currentDate.getTime() && isBeforeTenPM && tempDate.getTime()!==endDate.getTime()){
//           dates.push(tempDate.toDateString());
//         }
//       }
//         tempDate.setDate(tempDate.getDate() + 1);
//     }
//       setThisWeek(dates);
// }

const getCurrentWeekEnabled = () => {
  // Retrieve the start and end dates of the current week
  const { startDate, endDate } = getCurrentWeekDates();
  let tempDate = new Date(startDate);

  // Reset times to midnight for accurate date-only comparison
  const currentDate = resetTimeToMidnight(new Date()); // Reset current date to midnight
  const endDateMidnight = resetTimeToMidnight(endDate); // Reset end date to midnight
  tempDate = resetTimeToMidnight(tempDate); // Reset temp date to midnight

  const dates = [];
  const now = new Date();
const hours = now.getHours();
const minutes = now.getMinutes();
  // const isBeforeTenPM = (new Date().getHours() < 22); // Check if the current time is before 10 PM
  const isBeforeTenPM = (hours < 14) || (hours === 14 && minutes === 54);


  console.log("start date this week: ", startDate);
  console.log("end date this week: ", endDateMidnight);
  console.log("currentDate this week: ", currentDate);

  // Loop through each day of the week from startDate to endDate
  while (tempDate.getTime() <= endDateMidnight.getTime()) {
    // Ensure it's a future date
    if (currentDate.getTime() < tempDate.getTime()) {

      if (
        // tempDate.getTime() !== currentDate.getTime() && // Check if tempDate is not the same as currentDate
        isBeforeTenPM 
        // &&                                // Check if the current time is before 10 PM
        // tempDate.getTime() !== endDateMidnight.getTime() // Check if tempDate is not the same as endDate
      ) {
        console.log("REACHIGGGGGGGGG")
        dates.push(tempDate.toDateString()); // Add the tempDate to dates array as a string
      }
      // }else if(!isBeforeTenPM && if it is previous day or something){

      // }
    }
    tempDate.setDate(tempDate.getDate() + 1); // Move to the next day
    tempDate = resetTimeToMidnight(tempDate); // Reset tempDate to midnight
  }
  console.log("DATES: ",dates)
  setThisWeek(dates); // Update the state or variable with the dates array
};


getCurrentWeekEnabled();
},[isBeforeTenPM]);

useEffect(()=>{
const getMenu=async ()=>{

  const { startDate, endDate } = getCurrentWeekDates();
  const startDateToSend=formatDate(startDate.toDateString());
  const endDateToSend=formatDate(endDate.toDateString());

  if(startDateToSend && endDateToSend){
    const customHeaders2 = {
      "Content-Type": "application/json",
      "StartDate": startDateToSend,
      "EndDate": endDateToSend,
  };
  
  try {
      const res = await fetch(`${BASEURL}/fetchMenu`, {
          method: "GET",
          headers: customHeaders2
      });
  
      const data = await res.json();
  
      if (data.length === 0) {
          console.log("No tiffin yet!");
          setMenu([]);
      } else if (data.length >= 1) {
        data.forEach(item => {
          const dateObject = new Date(item.meal_date);
          item.formatted_date = dateObject.toDateString();
        });
        }
          setMenu(data);
          console.log("MENU : ",menu)
          console.log("MENU : ",data)
  } catch (error) {
      console.log("error in catch of yes users: ", error);
  }   
  }
 
}
getMenu();
},[])

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

return (
<div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">{user?.fname||''}'s Calendar</h1>
                    <h3 className="display-3 text-white mb-3 animated slideInDown">Your cancellations left: {reverseCounter}</h3>
                    <button data-bs-toggle="modal" data-bs-target="#modalAlert3" className="btn btn-primary w-25 py-3" type="submit">Menu this week</button>

                </div>
      </div>
    <div className="container">
       <div style={{marginTop:"60px",marginBottom:"20px"}}>
      </div>
      <div style={{marginBottom:"30px"}}>
       <label>Start date of subscription:<h6>{inputDate}</h6></label>
    
     </div>
      <div className="row">
        {subscriptionDates && subscriptionDates.map((date, index) => (
          <div key={index} className="col-md-2 calendar-cell">
            <p className='mb-4' style={{fontSize:"1rem"}}>{date}</p>
            <p className='mb-4' style={{ fontSize: "0.9rem", color: "#666" }}>
              Meal : {menu.find(item => item.formatted_date === date)?.meal || "No meal available"}
            </p>
            <div>
            <button data-bs-toggle="modal" data-bs-target="#modalAlert" onClick={()=>setCancelDate(date)} disabled={!thisWeek.includes(date) || buttonStates[date]?.isCanceled} className='mb-2 cancel-button'>Cancel</button>
            <button data-bs-toggle="modal" data-bs-target="#modalAlert2" disabled={!thisWeek.includes(date)||!buttonStates[date]?.canReverse} className='cancel-button'>Reverse Cancel</button>
            </div>
          </div>
        ))}
      </div>
      <div>

<div className="modal fade" id="modalAlert" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Cancel Tiffin</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        Are you sure you want to cancel the tiffin on <b>{cancelDate}</b> ?
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" id="cancelButton" data-bs-dismiss="modal" onClick={()=>cancelBtn(cancelDate)}>Yes</button>
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="modalAlert2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Reverse Cancel Tiffin</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        Are you sure you want to reverse the cancellation of tiffin on <b>{cancelDate}</b> ?
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" id="cancelButton" data-bs-dismiss="modal"  onClick={()=>reverseNO(user.id,cancelDate)}>Yes</button>
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="modalAlert3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role='document'>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Menu this week</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
    {
      !menu ? (
        <h6>No Tiffin yet!!</h6>
      ) : (
        menu.map((item, meal_id) => (
          <div className="row mb-2" key={meal_id}>
            <div className="col-md-12">
              <div className="menu-item d-flex justify-content-between p-3">
                <strong>{item.formatted_date}</strong>
                <span>{item.meal}</span>
              </div>
            </div>
          </div>
        ))
      )
    }
  </div> 
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
    </div>
  );
};

export default Tiffin;