import React,{useState,useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom';

const Register=()=> {

  const BASEURL = process.env.REACT_APP_BASE_URL;
  const navigate=useNavigate();
  const [errors, setErrors] = useState({});

  const [user,setUser] = useState({
    fname:"",
    lname:"",
    email:"",
    address:"",
    postal:"",
    pno:0,
    apno:0,
    startDate:"",
    pwd:"",
    cpwd:"",
    choice:""
  });

  let name,value;
  const handleInputs = (e)=>{
    name = e.target.name;
    value=e.target.value;

    setUser({...user,[name]:value});
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  }

  const validatePhoneNumber = () => {
    let errors = {};

    if (user.pno !== '') {
      if (user.pno.length < 10) {
        errors.pno = 'Enter a valid 10 digit phone number!';
      } else if (!/^\d*$/.test(user.pno)) {
        errors.pno = 'Enter a valid phone number!';
      }
    }

    setErrors(errors);
  };

  const handlePasswords = (e) => {
    name = e.target.name;
    value = e.target.value;

    let rule1 = document.getElementById("rule1");
    let rule2 = document.getElementById("rule2");
    let rule3 = document.getElementById("rule3");

    if(value.toString().length >= 8 ? rule1.style.color = 'green' : rule1.style.color = 'grey');
    if(/\d/.test(value) ? rule2.style.color = 'green' : rule2.style.color = 'grey');
    if(/[!@#$%^&*]/.test(value) ? rule3.style.color = 'green' : rule3.style.color = 'grey');       

    setUser({ ...user, [name]: value });
}

  const handleRadios=(e)=>{
    const {name,value} = e.target;

    setUser((prevUser)=>({
      ...prevUser,
      [name]:value
    }))
  }

  const postData=async (e)=>{
    e.preventDefault();
    const {fname,lname,email,address,postal,pno,apno,startDate,pwd,cpwd,choice}=user;


    const res= await fetch(`${BASEURL}/register`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body:JSON.stringify({
        fname,lname,email,address,postal,pno,apno,startDate,pwd,cpwd,choice
      })
    });

    const data = await res.json();

    if(res.status===201){
      setUser({});
      alert("Registration successful!");
      navigate('/')
    }
    else if(data.message === "Please fill all the required fields!"){
      alert("Please fill up all the details");
   }
   else if(data.message === "User already exists!"){
    alert("This user already exists!");
 }
    else{
      alert("User could not be registered!");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <div className="container-xxl position-relative p-0">
      <div className="container-xxl py-1 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Tiffin Registration</h1>
                </div>
      </div>
  <form style={{alignItems:"center"}}>
  <div className="row mb-2">
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input className="form-control" type="text" id="fname" name="fname" value={user?.fname||''} onChange={handleInputs}/>        <label className="form-label" htmlFor="fname">First name</label>
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="text" id="lname" name="lname" className="form-control" value={user?.lname||''} onChange={handleInputs}/>        <label className="form-label" htmlFor="lname">Last name</label>
      </div>
    </div>
  </div>

  <div className="row mb-2">
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="email" id="email" name="email" className="form-control" value={user?.email||''} onChange={handleInputs}/>
        <label className="form-label" htmlFor="email">Email</label>
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="text" id="address" name="address" className="form-control" value={user?.address||''} onChange={handleInputs}/>
        <label className="form-label" htmlFor="address">Address</label>
      </div>
    </div>
  </div>

  <div className="row mb-2">
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="tel" id="pno" name="pno" className="form-control" value={user?.pno||''} onChange={handleInputs} onBlur={validatePhoneNumber} />

        {/* {(user.pno !== '' && user.pno.length <10) && <p style={{ color: 'grey',textAlign:"left",marginTop:"1px" }}>Enter a valid 10 digit phone number!</p>}
        {(user.pno !== '' && !/^\d*$/.test(value)) && <p style={{ color: 'grey',textAlign:"left",marginTop:"1px" }}>Enter a valid phone number!</p>} */}
      {errors.pno && <p style={{ color: 'grey', textAlign: 'left', marginTop: '1px' }}>{errors.pno}</p>}

        <label className="form-label" htmlFor="pno">Contact No.</label>
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="text" id="postal" name="postal" className="form-control" value={user?.postal||''} onChange={handleInputs}/>
        <label className="form-label" htmlFor="postal">Postal code</label>
        {(user.postal !== '' && user.postal.length <6) && <p style={{ color: 'grey',textAlign:"left",marginTop:"1px" }}>Enter a valid postal code!</p>}
      </div>
    </div>
  </div>

  <div className="row mb-2">
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="date" id="startDate" name="startDate" className="form-control" value={user?.startDate||''} onChange={handleInputs}/>
        <label className="form-label" htmlFor="startDate">Start date</label>
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="tel" id="apno" name="apno" className="form-control" value={user?.apno||''} onChange={handleInputs}/>
        
        {(user.apno !== '' && user.apno.length <10) && <p style={{ color: 'grey',textAlign:"left",marginTop:"1px" }}>Enter a valid phone number!</p>}
        {(user.apno !== '' && (user.pno === user.apno)) && <p style={{ color: 'grey',textAlign:"left",marginTop:"1px" }}>Enter a different phone number!</p>}

        <label className="form-label" htmlFor="apno">Alternate Contact No.</label>
      </div>
    </div>
  <div className="row mb-2">
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="password" id="pwd" name="pwd" className="form-control" value={user?.pwd||''} onChange={handlePasswords}/>
        <label className="form-label" htmlFor="pwd">Password</label>
        <pre id="changeStyles" style={{ color: 'grey',textAlign:"left",marginTop:"1px",whiteSpace:"pre-wrap",fontSize:"12px" }}>
                        Password should be: 
                            <ul>
                                <li id='rule1'>
                                At least 8 characters long  
                                </li>
                                <li id='rule2'>
                                Should contain at least a number
                                </li>
                                <li id='rule3'>
                                Should contain at least one special character from ! @ # $ % ^ & *
                                </li>                         
                            </ul>           
                        </pre>
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline">
        <input type="password" id="cpwd" name="cpwd" className="form-control" value={user?.cpwd||''} onChange={handleInputs}/>
        {(user.pwd !== user.cpwd) && <p style={{ color: 'grey',textAlign:"left",marginTop:"1px" }}>Passwords do not match!</p>}

        <label className="form-label" htmlFor="cpwd">Confirm Password</label>
      </div>
    </div>
  </div>
  <div className="col">
    Delivery/Self-checkout                        :   
    <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="choice" id="delivery" value="d" checked={user?.choice === 'd' || ''} onChange={handleRadios}/>
  <label className="form-check-label" htmlFor="delivery">Delivery</label>
    </div>
    <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="choice" id="pickup" value="p" checked={user?.choice === 'p'|| ''} onChange={handleRadios}/>
  <label className="form-check-label" htmlFor="pickup">Pick up</label>
    </div>
  </div>
  </div>
  <button data-mdb-ripple-init type="button" className="btn btn-primary btn-block mb-2" onClick={postData}>Register</button>
    </form>
    <button data-mdb-ripple-init type="button" className="btn btn-primary btn-block mb-2"><Link to='/login'>Login</Link></button>
    </div>
    </div>
  )
}

export default Register
